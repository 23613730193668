//column对应数据库表字段名称，dic_name对应字典表名称
//options对应选项,多条输入的时候用,跟dic_name没关系
//edit 学生是否可编辑,required是否必填,show是否显示出来,默认不显示，,relate:1关联显示字段，只做显示
let baseInfo=[
    {name:'头像',column:'avatar',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
    {name:'一寸照片',column:'photo',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'学号',column:'stu_code',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'考生号',column:'xuejihao',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'姓名',column:'stu_name',dic_name:'',options:[],default:"",edit:1,required:1,show:1},

{name:'曾用名',column:'old_name',dic_name:'',options:[],default:"",edit:1,required:1,show:1},

{name:'性别',column:'gender',dic_name:'',options:[],default:"",edit:0,required:1,show:1},
{name:'身份证号',column:'idcard_encrypt',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'出生年月',column:'birthday',dic_name:'',options:[],default:"",edit:0,required:1,show:1},
{name:'籍贯',column:'jiguan',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'政治面貌',column:'zzmm',dic_name:'POLITICAL_STATUS',options:[],default:"团员",edit:1,required:1,show:1},
{name:'民族',column:'minzu',dic_name:'NATION',options:[],default:"",edit:1,required:1,show:1},
{name:'手机号码',column:'phone',dic_name:'',options:[],default:"",edit:1,required:1,show:1},

{name:'电子邮箱',column:'dzyx',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'QQ号码',column:'qq',dic_name:'',options:[],default:"",edit:1,required:0,show:1},

{name:'父亲电话',column:'jhr1phone',dic_name:'',options:[],default:"",edit:1,required:1,show:1},

{name:'母亲电话',column:'jhr2phone',dic_name:'',options:[],default:"",edit:1,required:1,show:1},

{name:'所在地区',column:'suozaidi',dic_name:'',options:[],default:"",edit:1,required:0,show:1},

{name:'详细地址',column:'jtzz',dic_name:'',options:[],default:"",edit:1,required:1,show:1},

{name:'家庭邮编',column:'zip_code',dic_name:'',options:[],default:"",edit:1,required:0,show:1},

{name:'微信号',column:'weixin',dic_name:'',options:[],default:"",edit:1,required:0,show:1},

{name:'掌握外语种类',column:'efl_type',dic_name:'LANGUAGE',options:[],default:"英语",edit:1,required:1,show:1},
{name:'掌握外语种类及熟练程度',column:'efl_proficiency',dic_name:'lng_level',options:[],default:"熟练",edit:1,required:1,show:1},
{name:'计算机水平',column:'computer_level',dic_name:'COMPUTER_LEVEL',options:[],default:"良好",edit:1,required:1,show:1},
{name:'生源地',column:'place_of_origin',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'所属学校',column:'platform',dic_name:'',options:[],default:"",edit:0,required:1,show:1,relate:1},
{name:'学校代码',column:'platform_code',dic_name:'',options:[],default:"",edit:0,required:0,show:1,relate:1},
{name:'所属学院',column:'xueyuan',dic_name:'',options:[],default:"",edit:0,required:1,show:1,relate:1},
{name:'学制',column:'zy_xingzhi',dic_name:'',options:[],default:"",edit:0,required:1,show:1,relate:1},
{name:'学年',column:'academic_year',dic_name:'',options:[],default:"",edit:0,required:0,show:0,relate:1},
{name:'学期',column:'semester',dic_name:'',options:[],default:"第二学年",edit:0,required:0,show:1,relate:1},
{name:'学校专业',column:'zy_name',dic_name:'',options:[],default:"",edit:0,required:1,show:1,relate:1},
{name:'专业代码',column:'zy_code',dic_name:'',options:[],default:"",edit:0,required:0,show:1,relate:1},
{name:'专业方向',column:'zy_type',dic_name:'',options:[],default:"",edit:0,required:0,show:1,relate:1},
{name:'所在班级',column:'class_name',dic_name:'',options:[],default:"",edit:0,required:1,show:1,relate:1},
{name:'入学时间',column:'ctime',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'毕业时间',column:'graduate',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'培养方式 ',column:'training_type',dic_name:'peiyangfangshi',options:[],default:"非定向",edit:1,required:1,show:1},
{name:'委培单位',column:'commission',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'学习形式',column:'learn',dic_name:'xuexixingshi',options:[],default:"全日制",edit:1,required:1,show:1},
{name:'专业主要课程',column:'course',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'在校期间担任学校的主要职务情况',column:'duties',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'奖惩情况',column:'awards',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'专业技能',column:'skill_json',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'专业证书',column:'skill_img',dic_name:'SKILL_TYPE',options:[],default:"",edit:1,required:1,show:1},
{name:'技能大赛最高奖项',column:'skills_competition',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'校级及以上奖励',column:'school_level',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'班主任评语',column:'remark',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'健康状况',column:'health',dic_name:'HEALTH',options:[],default:"",edit:1,required:1,show:1},
{name:'身高（CM）',column:'height',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'体重（KG）',column:'weight',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'特长爱好',column:'specialty',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
{name:'个人小结',column:'summary',dic_name:'',options:[],default:"",edit:1,required:1,show:1},
]

let shixiInfo = [
    {name:'实习去向',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习项目名称',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习开始时间',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习结束时间',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习时长',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习单位名称',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习岗位名称',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习成绩',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习薪酬',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习单位来源',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'法定监护人（或家长）是否同意',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习指导教师工号',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'校内指导教师姓名',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'校内指导教师电话',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'周工作时间',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'日工作时长',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习安排',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习形式',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'是否赴国',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'是否跨省实习',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'是否签订三方协议',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习地所在省份',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习地所在地市',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习详细地址',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'实习场所',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'训练技能',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'专业对口程度',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'录用意向',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'企业专门人员姓名',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'企业专门人员电话',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'校级监督咨询电话',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'保单号',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'保险险种名称',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'购买保险种类一',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'保险购买方一',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'购买保险种类二',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},
{name:'保险购买方二',column:'',dic_name:'',options:[],default:"",edit:1,required:0,show:1},

]
let jiuyeInfo = [
    {name:'毕业去向',column:'sxqx',dic_name:'SHIXI_STATUS',options:[],default:"",edit:0,required:0,show:1},
{name:'单位名称',column:'firm_name',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'单位性质',column:'enter_type',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'联系人手机',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'联系人传真',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'单位邮编',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'签约日期',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'单位行业',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'单位所在地',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'工作职位类别',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'统一社会信用代码',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'单位联系人姓名',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'单位联系人电话',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'单位联系人邮箱',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'单位地址',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'报到证签发类别',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'报到证签往单位名称',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'签往单位所在地',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'档案转寄单位名称',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'档案转寄单位地址',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'档案转寄单位邮编',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'档案转寄单位联系人',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'档案转寄单位电话',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'部门',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'岗位',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'专业是否对口',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'有否劳动合同',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'有否三险五险',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'有否公积金',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'平均月工资(元)',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'升学渠道',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'录取专业',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'分数',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'层次',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'硕士类型',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'是否参军',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'就业单位是否校企合作单位',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'规模',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'就业省',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'就业地市（直辖市区县）',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'就业时间',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'是否自主创业',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'创业项目名称',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'是否灵活就业 ',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'灵活就业内容 ',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'产业类型',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'企业类型',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'地域',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'资格证书情况',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'起薪线(元)',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'就业满意度',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'就业满意度',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'就业类别',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},
{name:'面向产业',column:'',dic_name:'',options:[],default:"",edit:0,required:0,show:1},

]

module.exports = {
    baseInfo,
    shixiInfo,
    jiuyeInfo
  }

