<template>
    <div class="cbox">
        <div class="headbar">

            <div style="padding-top: 14px;">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>基础设置</el-breadcrumb-item>
                    <el-breadcrumb-item>学生信息采集配置</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="bcontent">

            <el-row :gutter="20">
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>学生基础信息-{{ baseSaved ? '已配置' : '未配置' }}</span>
                            <el-button style="float: right; " type="primary" size="small"
                                @click="setConfig('base_json')">保存</el-button>
                        </div>

                        <div>
                            <el-table :data="baseInfo" style="width:fit-content;width:100%;">
                                <el-table-column label="字段名称" prop="name" min-width="100" align="center" />
                                <el-table-column label="启用" prop="stu_name" width="100" align="center">

                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.show" :true-label="1"
                                            :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column label="可编辑" prop="stu_name" width="80" align="center">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.edit" :true-label="1"
                                            :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column label="必填" prop="stu_name" width="80" align="center">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.required" :true-label="1"
                                            :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column label="默认值" prop="default" min-width="80" align="center">
                                    <template slot-scope="scope">
                                        <el-popover placement="top" width="160" v-model="visible">
                                            <div>
                                                <el-input type="text" v-model="scope.row.default" size="mini"
                                                    placeholder="请输入默认值"></el-input>

                                            </div>
                                            <span slot="reference">{{ scope.row.default ? scope.row.default : "--" }}</span>
                                        </el-popover>
                                    </template>
                                </el-table-column>

                            </el-table>

                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>实习信息-{{ shixiSaved ? '已配置' : '未配置' }}</span>
                            <el-button style="float: right; " type="primary" size="small"
                                @click="setConfig('shixi_json')">保存</el-button>
                        </div>

                        <div>

                            <el-table :data="shixiInfo" style="width:fit-content;width:100%;">
                                <el-table-column label="字段名称" prop="name" min-width="100" align="center" />
                                <el-table-column label="启用" prop="stu_name" width="100" align="center">

                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.show" :true-label="1"
                                            :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column label="可编辑" prop="stu_name" width="100" align="center">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.edit" :true-label="1"
                                            :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column label="必填" prop="stu_name" width="100" align="center">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.required" :true-label="1"
                                            :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>

                            </el-table>

                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>就业信息</span>
                            <span style="float: right;">从91job自动同步，无需学生填写</span>
                        </div>
                        <div style="position: relative;">
                            <el-table :data="jiuyeInfo" style="width:fit-content;width:100%;">
                                <el-table-column label="字段名称" prop="name" min-width="100" align="center" />
                                <el-table-column label="启用" prop="stu_name" width="100" align="center">

                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.show" :true-label="1"
                                            :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column label="可编辑" prop="stu_name" width="80" align="center">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.edit" :true-label="1"
                                            :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column label="必填" prop="stu_name" width="80" align="center">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.required" :true-label="1"
                                            :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>



                            </el-table>

                            <div
                                style="position: absolute; width: 100%; height: 100%; top: 0; z-index: 1; cursor: not-allowed;">
                            </div>

                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
  
<script>
let ziduan = require("./xxcj_ziduan")
export default {
    data() {
        return {
            id: 0,
            baseSaved: false,
            shixiSaved: false,
            baseInfo: ziduan.baseInfo,
            shixiInfo: ziduan.shixiInfo,
            jiuyeInfo: ziduan.jiuyeInfo
        }
    },
    mounted() {
        this.getConfig()
    },
    methods: {
        getConfig() {
            this.$http.post("/api/get_xxcj_config").then(res => {
                if (res.data) {
                    this.id = res.data.id
                    if (res.data.base_json) {
                        this.baseSaved = true
                        let baseInfo = JSON.parse(res.data.base_json)
                        for (let i = 0; i < this.baseInfo.length; i++) {
                            for (let j = 0; j < baseInfo.length; j++) {
                                if(this.baseInfo[i].column == baseInfo[j].column){
                                    this.baseInfo[i] = baseInfo[j]
                                    console.log(this.baseInfo[i])
                                    break
                                }
                            }
                        }

                        this.baseInfo = JSON.parse(JSON.stringify( this.baseInfo))

                        this.$nextTick(e=>{
                            this.$forceUpdate()
                        })

                    }
                    if (res.data.shixi_json) {
                        this.shixiSaved = true
                        this.shixiInfo = JSON.parse(res.data.shixi_json)
                    }
                }
            })
        },
        setConfig(t) {

            let config_json = []
            if (t == 'base_json') {
                config_json = this.baseInfo
            } else if (t == 'shixi_json') {
                config_json = this.shixiInfo
            }
            this.$http.post("/api/save_xxcj_config", { id: this.id, type: t, config_json: config_json }).then(res => {
                this.getConfig()
                this.$message.success("保存成功")
            })
        }
    }
};
</script>
  
<style scoped lang="less"></style>